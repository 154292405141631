import {
  AddShoppingCart,
  Delete,
  LocalTaxi,
  PersonSearch,
} from '@mui/icons-material';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
import { api } from 'common';
import { CircularLoading } from 'components';
import { Roles } from 'models';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { Android12Switch, ViewCarDetails, ViewUserDetails } from './Components';
import moment from 'moment';
import { DrawerContext } from 'context/DrawerContext/DrawerContext';
import { CarCreateForm } from 'forms';

const DriverPage2 = () => {
  const auth = useSelector(state => state.auth);
  const staticusers = useSelector(state => state.usersdata.staticusers);
  const dispatch = useDispatch();
  const {
    fetchUsersOnce,
    deleteUser,
    updateUserApproval,
    updateUserServiceStatus,
    updateUserCar,
    updateCarAprroval,
  } = api;
  const loaded = useRef(false);
  const [rows, setRows] = useState([]);
  const { handleOpenDrawer, updateContent } = useContext(DrawerContext);
  const [cityTab, setCityTab] = useState('Cúcuta');

  const handleDeleteUser = id => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: '¡No podrás revertir esto!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(deleteUser(id));
        dispatch(fetchUsersOnce());
        Swal.fire('¡Eliminado!', 'El conductor ha sido eliminado.', 'success');
      }
    });
  };

  const updateApproval = async (id, approved) => {
    try {
      await updateUserApproval(id, approved);
      dispatch(fetchUsersOnce());
      Swal.fire(
        '¡Acción completa!',
        'Se ha actualizado la aprobación del conductor',
        'success',
        {
          timer: 2000,
        },
      );
    } catch (err) {
      console.error(err);
      Swal.fire('¡Error!', err.msg, 'error');
    }
  };

  const handleUserApproval = (id, approved) => {
    Swal.fire({
      title: `¿Estas seguro de ${
        !approved ? 'desaprobar' : 'aprobar'
      } este conductor?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: !approved ? 'Desaprobar' : 'Aprobar',
      cancelButtonText: 'Cancelar',
    }).then(result => {
      if (result.isConfirmed) {
        updateApproval(id, approved);
      }
    });
  };

  const updateService = async (id, queue) => {
    try {
      await updateUserServiceStatus(id, queue);
      dispatch(fetchUsersOnce());
      Swal.fire(
        '¡Acción completa!',
        `Se ha ${!queue ? 'desactivado' : 'activado'} el conductor`,
        'success',
        {
          timer: 2000,
        },
      );
    } catch (err) {
      console.error(err);
      Swal.fire('¡Error!', err.msg, 'error');
    }
  };

  const handleUserService = (id, queue) => {
    Swal.fire({
      title: `¿Estas seguro de ${
        !queue ? 'desactivar' : 'activar'
      } este conductor?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: !queue ? 'Desactivar' : 'Activar',
      cancelButtonText: 'Cancelar',
    }).then(result => {
      if (result.isConfirmed) {
        updateService(id, queue);
      }
    });
  };

  const updateCarApproval = async (carId, approved) => {
    dispatch(
      updateUserCar(carId, {
        carApproved: !approved,
      }),
    );
    dispatch(updateCarAprroval(carId, !approved));
    dispatch(fetchUsersOnce());
  };

  const handleCarApproval = (carId, approved) => {
    Swal.fire({
      title: `¿Estas seguro de ${
        !approved ? 'desaprobar' : 'aprobar'
      } este coche?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: !approved ? 'Desaprobar' : 'Aprobar',
      cancelButtonText: 'Cancelar',
    }).then(result => {
      if (result.isConfirmed) {
        updateCarApproval(carId, approved ? false : true);
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Acciones',
        flex: 1, // Ajusta la columna dinámicamente
        getActions: params => {
          let actions = [
            <ViewUserDetails
              label="Detalles del conductor"
              icon={<PersonSearch />}
              userId={params.id}
              closeMenuOnClick={false}
            />,
          ];

          if (params.row.vehicleNumber) {
            actions.push(
              <ViewCarDetails
                label="Detalles del coche"
                icon={<LocalTaxi color="secondary" />}
                carId={params.row.id}
                closeMenuOnClick={false}
              />,
            );
          } else {
            actions.push(
              <GridActionsCellItem
                icon={<AddShoppingCart />}
                label="Asignar coche"
                name="Asignar coche"
                onClick={() => {
                  updateContent(<CarCreateForm userId={params.id} />);
                  handleOpenDrawer();
                }}
              />,
            );
          }

          return actions;
        },
      },
      {
        field: 'createdAt',
        type: 'Date',
        headerName: 'Creado',
        flex: 1, // Ajusta la columna dinámicamente
        valueGetter: (params, row) => {
          // Asegúrate de convertir createdAt a un objeto Date para la ordenación
          return row.createdAt ? new Date(row.createdAt) : null;
        },
        valueFormatter: (params, row) => {
          // Formatea la fecha para mostrarla en el formato deseado
          return row.createdAt
            ? new Date(row.createdAt).toLocaleDateString('en-GB') // Día/Mes/Año
            : '';
        },
      },
      {
        field: 'approved',
        type: 'boolean',
        headerName: 'Documentos',
        flex: 1, // Ajusta la columna dinámicamente
        renderCell: params => (
          <Android12Switch
            checked={params.row.approved}
            onChange={() => {
              handleUserApproval(params.id, !params.row.approved);
            }}
          />
        ),
      },
      {
        field: 'carApproved',
        type: 'string',
        headerName: 'Coche aprobado',
        flex: 1, // Ajusta la columna dinámicamente
        renderCell: params => (
          <Android12Switch
            checked={params.row.carApproved}
            onChange={() => {
              handleCarApproval(params.row.id, !params.row.carApproved);
            }}
          />
        ),
      },

      {
        field: 'driverActiveStatus',
        type: 'string',
        headerName: 'Estado',
        flex: 1, // Ajusta la columna dinámicamente
        renderCell: params =>
          params.row.driverActiveStatus ? 'Activo' : 'Inactivo',
      },
      {
        field: 'queue',
        type: 'boolean',
        headerName: 'En servicio',
        flex: 1, // Ajusta la columna dinámicamente
        renderCell: params => (
          <Android12Switch
            checked={params.row.queue}
            onChange={() => {
              handleUserService(params.id, !params.row.queue);
            }}
          />
        ),
      },
      {
        field: 'firstName',
        type: 'string',
        headerName: 'Datos del conductor',
        flex: 2, // Esta columna tendrá más espacio proporcionalmente
        valueGetter: (value, row) => {
          return `${value} ${row.lastName}`;
        },
      },
      {
        field: 'mobile',
        type: 'string',
        headerName: 'Teléfono',
        flex: 1.5, // Un poco más de espacio para esta columna
      },
      {
        field: 'vehicleNumber',
        type: 'string',
        headerName: 'Placa',
        flex: 1, // Ajusta la columna dinámicamente
      },
      {
        field: 'walletBalance',
        type: 'numeric',
        headerName: 'Saldo',
        flex: 1.5, // Un poco más de espacio proporcionalmente
      },
      {
        type: 'actions',
        flex: 0.5, // Ajusta la columna dinámicamente
        getActions: params => [
          <GridActionsCellItem
            icon={<Delete />}
            label="Delete"
            name="Eliminar conductor"
            title="Eliminar conductor"
            onClick={() => handleDeleteUser(params.id)}
            color="error"
          />,
        ],
      },
    ],
    [],
  );

  useEffect(() => {
    setCityTab(auth.profile.city ? auth.profile.city : 'Cúcuta');
    dispatch(fetchUsersOnce());
  }, []);

  useEffect(() => {
    loaded.current = false;

    if (!auth || !staticusers) return;

    // Solo continua si el usuario tiene un rol permitido
    if (
      ![Roles.FLEET_ADMIN, Roles.ADMIN, Roles.MANAGER].includes(
        auth.profile.usertype,
      )
    )
      return;

    // Filtramos para obtener solo los usuarios tipo DRIVER
    let newRows = staticusers.filter(user => user.usertype === Roles.DRIVER);

    // Actualizamos la ciudad a 'Cúcuta' en caso de que esté vacía
    newRows = newRows.map(r => ({
      ...r,
      city: r.city || 'Cúcuta',
    }));

    // Filtramos por la ciudad seleccionada en `cityTab`
    newRows = newRows.filter(r => r.city === cityTab);

    // Actualizamos las filas con el resultado final
    setRows(newRows);

    loaded.current = true;
  }, [auth, staticusers, cityTab]);

  if (!loaded.current) {
    return <CircularLoading />;
  }

  return (
    <div style={{ height: '70vh', width: '100%' }}>
      <Typography variant="h4" gutterBottom>
        Conductores
      </Typography>
      {auth.profile.usertype === Roles.ADMIN && (
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">Ciudad</FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="Cúcuta"
            name="radio-buttons-group"
            sx={{ display: 'flex', flexDirection: 'row' }}
          >
            <FormControlLabel
              value="Cúcuta"
              control={<Radio />}
              label="Cúcuta"
              onChange={() => setCityTab('Cúcuta')}
            />
            <FormControlLabel
              value="Bogotá"
              control={<Radio />}
              label="Bogotá"
              onChange={() => setCityTab('Bogotá')}
            />
            <FormControlLabel
              value="Sevilla"
              control={<Radio />}
              label="Sevilla"
              onChange={() => setCityTab('Sevilla')}
            />
            <FormControlLabel
              value="Medellín"
              control={<Radio />}
              label="Medellín"
              onChange={() => setCityTab('Medellín')}
            />
            <FormControlLabel
              value="Bucaramanga"
              control={<Radio />}
              label="Bucaramanga"
              onChange={() => setCityTab('Bucaramanga')}
            />
          </RadioGroup>
        </FormControl>
      )}

      <div style={{ height: '90%', width: '100%' }}>
        <DataGrid
          columns={columns}
          rows={rows}
          slots={{ toolbar: GridToolbar }}
          localeText={{
            // Column filter translations
            columnMenuLabel: 'Menú de columnas',
            columnMenuShowColumns: 'Mostrar columnas',
            columnMenuFilter: 'Filtrar',
            columnMenuHideColumn: 'Ocultar columna',
            columnMenuUnsort: 'Quitar orden',
            columnMenuSortAsc: 'Ordenar ascendente',
            columnMenuSortDesc: 'Ordenar descendente',

            // Filter panel translations
            filterPanelAddFilter: 'Añadir filtro',
            filterPanelDeleteIconLabel: 'Eliminar',
            filterPanelOperators: 'Operadores',
            filterPanelOperatorAnd: 'Y',
            filterPanelOperatorOr: 'O',
            filterPanelColumns: 'Columnas',
            filterPanelInputLabel: 'Valor',
            filterPanelInputPlaceholder: 'Valor para filtrar',

            // Filter operators translations
            filterOperatorContains: 'Contiene',
            filterOperatorEquals: 'Igual a',
            filterOperatorStartsWith: 'Empieza con',
            filterOperatorEndsWith: 'Termina en',
            filterOperatorIsEmpty: 'Está vacío',
            filterOperatorIsNotEmpty: 'No está vacío',
            filterOperatorIsAnyOf: 'Es alguno de',

            // Additional toolbar texts
            toolbarFilters: 'Filtros',
            toolbarFiltersLabel: 'Mostrar filtros',
            toolbarFiltersTooltipHide: 'Ocultar filtros',
            toolbarFiltersTooltipShow: 'Mostrar filtros',
            toolbarQuickFilterPlaceholder: 'Buscar…',
            toolbarQuickFilterLabel: 'Buscar',
            // Textos de paginación
            pagination: {
              labelDisplayedRows: '{from}-{to} de {count}',
              labelRowsPerPage: 'Filas por página:',
              firstButtonAriaLabel: 'Primera página',
              lastButtonAriaLabel: 'Última página',
              nextButtonAriaLabel: 'Página siguiente',
              previousButtonAriaLabel: 'Página anterior',
            },

            // Textos de componentes
            toolbarDensity: 'Densidad',
            toolbarDensityLabel: 'Densidad',
            toolbarDensityCompact: 'Compacta',
            toolbarDensityStandard: 'Estándar',
            toolbarDensityComfortable: 'Cómoda',

            // Texto de filtro
            toolbarFilters: 'Filtros',
            toolbarFiltersLabel: 'Mostrar filtros',
            toolbarFiltersTooltipHide: 'Ocultar filtros',
            toolbarFiltersTooltipShow: 'Mostrar filtros',
            toolbarQuickFilterPlaceholder: 'Buscar...',
            toolbarQuickFilterLabel: 'Buscar',

            // Texto de columnas
            columnMenuLabel: 'Menú',
            columnMenuSortAsc: 'Ordenar ascendente',
            columnMenuSortDesc: 'Ordenar descendente',
            columnMenuFilter: 'Filtrar',
            columnMenuHideColumn: 'Ocultar columna',
            columnMenuShowColumns: 'Mostrar columnas',

            // Texto de errores
            errorOverlayDefaultLabel: 'Ocurrió un error',

            // Texto de sin resultados y sin filas
            noRowsLabel: 'No hay filas',
            noResultsOverlayLabel: 'Sin resultados',

            // Texto de exportación
            toolbarExport: 'Exportar',
            toolbarExportLabel: 'Exportar',
            toolbarExportCSV: 'Descargar como CSV',
            toolbarExportPrint: 'Imprimir',

            // Texto de selección
            checkboxSelectionHeaderName: 'Selección',
            checkboxSelectionSelectAllRows: 'Seleccionar todas las filas',
            checkboxSelectionUnselectAllRows: 'Deseleccionar todas las filas',

            // Texto de clasificación
            sort: {
              ascending: 'ascendente',
              descending: 'descendente',
            },

            // Tooltip de agrupación de columnas
            columnGroupingHeaderName: 'Agrupación de columnas',

            // Texto de rango de selección de celdas
            rangeSelectionRowLabel: 'Filas seleccionadas',

            // Otros textos comunes
            toolbarColumns: 'Columnas',
            toolbarColumnsLabel: 'Seleccionar columnas',
            toolbarColumnsTooltipShow: 'Mostrar columnas',
            toolbarColumnsTooltipHide: 'Ocultar columnas',
          }}
          initialState={{
            sorting: [
              {
                field: 'createdAt',
                order: 'desc',
              },
            ],
          }}
        />
      </div>
    </div>
  );
};

export default DriverPage2;
