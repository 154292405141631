import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { api } from 'common';
import { CircularLoading } from 'components';
import { AlertDialog } from 'components';
import { Button } from 'components';
import { DrawerContext } from 'context/DrawerContext/DrawerContext';
import { UserForm } from 'forms';
import { useContext, useEffect, useState } from 'react';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import Swal from 'sweetalert2';

const ViewUserDetails = ({ userId, ...props }) => {
  const [open, setOpen] = useState(false);
  const { getUserById, resetUserPassword } = api;
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { handleOpenDrawer, updateContent } = useContext(DrawerContext);

  const handleResetPassword = async newPassword => {
    try {
      const response = await resetUserPassword(userId, newPassword);
      setOpen(false);
      Swal.fire({
        icon: 'success',
        title: 'Contraseña reseteada',
        text: response.message,
        timer: 2000,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const editUser = () => {
    updateContent(<UserForm data={{ ...userData, uid: userId }} />);
    handleOpenDrawer();
    setOpen(false);
  };

  const fetchUserData = async () => {
    setLoading(true);
    try {
      const response = await getUserById(userId);
      setUserData(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchUserData();
    setLoading(false);
  }, [userId]);

  return (
    <>
      <GridActionsCellItem {...props} onClick={() => setOpen(true)} />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiDialog-paper': {
            width: '100%',
            maxWidth: 'md',
          },
        }}
      >
        {!userData ? (
          <CircularLoading />
        ) : (
          <>
            <DialogTitle
              id="alert-dialog-title"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                flexDirection: 'row',
                gap: 2,
              }}
            >
              <Avatar
                alt={userData.firstName}
                src={userData.profile_image}
                sx={{ width: 82, height: 82 }}
              />
              {userData.firstName} {userData.lastName}
              <Box
                sx={{
                  backgroundColor: userData.approved ? '#4caf50' : '#f44336',
                  color: '#fff',
                  paddingInline: 2,
                  borderRadius: 2,
                }}
              >
                {userData.approved ? 'Aprobado' : 'No aprobado'}
              </Box>
            </DialogTitle>

            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography variant="body2" gutterBottom>
                    Teléfono
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {userData.mobile}
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography variant="body2" gutterBottom>
                    Correo electrónico
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {userData.email}
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography variant="body2" gutterBottom>
                    Identificación
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {userData.identification}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" gutterBottom>
                    Extra. info
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {userData.other_info}
                  </Typography>
                </Grid>
              </Grid>

              <Divider sx={{ marginTop: 3 }} />
              <Typography>DOCUMENTOS</Typography>
              <Divider sx={{ marginBottom: 3 }} />
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <PhotoProvider>
                    <PhotoView src={userData.licenseImage}>
                      <img
                        alt="licencia frontal"
                        src={userData.licenseImage}
                        style={{
                          width: '100%',
                          height: 'auto',
                        }}
                      />
                    </PhotoView>
                  </PhotoProvider>
                </Grid>
                <Grid item xs={4}>
                  <PhotoProvider>
                    <PhotoView src={userData.licenseImageBack}>
                      <img
                        alt="licencia frontal"
                        src={userData.licenseImageBack}
                        style={{
                          width: '100%',
                          height: 'auto',
                        }}
                      />
                    </PhotoView>
                  </PhotoProvider>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={4}>
                  <PhotoProvider>
                    <PhotoView src={userData.operatingCard}>
                      <img
                        alt="licencia frontal"
                        src={userData.operatingCard}
                        style={{
                          width: '100%',
                          height: 'auto',
                        }}
                      />
                    </PhotoView>
                  </PhotoProvider>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={4}>
                  <PhotoProvider>
                    <PhotoView src={userData.ownershipCard}>
                      <img
                        alt="licencia frontal"
                        src={userData.ownershipCard}
                        style={{
                          width: '100%',
                          height: 'auto',
                        }}
                      />
                    </PhotoView>
                  </PhotoProvider>
                </Grid>
                <Grid item xs={4}>
                  <PhotoProvider>
                    <PhotoView src={userData.ownershipCardBack}>
                      <img
                        alt="licencia frontal"
                        src={userData.ownershipCardBack}
                        style={{
                          width: '100%',
                          height: 'auto',
                        }}
                      />
                    </PhotoView>
                  </PhotoProvider>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={4}>
                  <PhotoProvider>
                    <PhotoView src={userData.tarjeton}>
                      <img
                        alt="licencia frontal"
                        src={userData.tarjeton}
                        style={{
                          width: '100%',
                          height: 'auto',
                        }}
                      />
                    </PhotoView>
                  </PhotoProvider>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button
                onClick={() => {
                  setOpen(false);
                  Swal.fire({
                    title: 'Resetea la contraseña',
                    input: 'text',
                    inputLabel: 'Nueva contraseña',
                    inputPlaceholder: 'Nueva contraseña',
                    showCancelButton: true,
                    inputValidator: value => {
                      if (!value) {
                        return 'Debes ingresar una contraseña';
                      }
                    },
                  }).then(result => {
                    if (result.isConfirmed) {
                      handleResetPassword(result.value);
                    }
                  });
                }}
                color={'warning'}
              >
                Resetear contraseña
              </Button>
              <Button onClick={() => editUser()} color={'info'}>
                Editar conductor
              </Button>
              <Button onClick={() => setOpen(false)} color={'error'}>
                Cerrar
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default ViewUserDetails;
