import React, { createContext } from 'react';
import { initializeApp, getApp, getApps } from 'firebase/app';
import {
  getDatabase,
  ref,
  query,
  orderByChild,
  equalTo,
} from 'firebase/database';
import {
  initializeAuth,
  getAuth,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPhoneNumber,
  PhoneAuthProvider,
  RecaptchaVerifier,
  unlink,
  updatePhoneNumber,
  linkWithPhoneNumber,
  browserLocalPersistence,
  browserPopupRedirectResolver,
} from 'firebase/auth';
import { getStorage, ref as stRef } from 'firebase/storage';
import { getReactNativePersistence } from 'firebase/auth/react-native';

const FirebaseContext = createContext(null);

let firebase = {
  app: null,
  database: null,
  auth: null,
  storage: null,
};

const createFullStructure = (app, db, auth, storage, config) => {
  return {
    app: app,
    config: config,
    database: db,
    auth: auth,
    storage: storage,
    authRef: getAuth,
    facebookProvider: new FacebookAuthProvider(),
    googleProvider: new GoogleAuthProvider(),
    appleProvider: new OAuthProvider('apple.com'),
    phoneProvider: new PhoneAuthProvider(auth),
    RecaptchaVerifier: RecaptchaVerifier,
    signInWithPhoneNumber: signInWithPhoneNumber,
    updatePhoneNumber: updatePhoneNumber,
    unlink: unlink,
    linkWithPhoneNumber: linkWithPhoneNumber,
    facebookCredential: token => FacebookAuthProvider.credential(token),
    facebookCredentialFromResult: token =>
      FacebookAuthProvider.credentialFromResult(token),
    mobileAuthCredential: (verificationId, code) =>
      PhoneAuthProvider.credential(verificationId, code),
    usersRef: ref(db, 'users'),
    bookingRef: ref(db, 'bookings'),
    cancelreasonRef: ref(db, 'cancel_reason'),
    settingsRef: ref(db, 'settings'),
    carTypesRef: ref(db, 'cartypes'),
    carTypesEditRef: id => ref(db, 'cartypes/' + id),
    carDocImage: id => stRef(storage, `cartypes/${id}`),
    promoRef: ref(db, 'promos'),
    promoEditRef: id => ref(db, 'promos/' + id),
    notifyRef: ref(db, 'notifications/'),
    notifyEditRef: id => ref(db, 'notifications/' + id),
    singleUserRef: uid => ref(db, 'users/' + uid),
    profileImageRef: uid => stRef(storage, `users/${uid}/profile_image`),
    verifyIdImageRef: uid => stRef(storage, `users/${uid}/verifyIdImage`),
    bookingImageRef: (bookingId, imageType) =>
      stRef(storage, `bookings/${bookingId}/${imageType}`),
    //driversRef: query(ref(db, 'users'), orderByChild('queue'), equalTo(false)),
    driversRef: query(ref(db, 'users')),
    driverDocsRef: uid => stRef(storage, `users/${uid}/license`),
    driverDocsRefBack: uid => stRef(storage, `users/${uid}/licenseBack`),
    driverOperatingCardRef: uid => stRef(storage, `users/${uid}/operatingCard`),
    driverOwnershipCardRef: uid => stRef(storage, `users/${uid}/ownershipCard`),
    driverOwnershipCardBackRef: uid =>
      stRef(storage, `users/${uid}/ownershipCardBack`),
    driverTarjetonRef: uid => stRef(storage, `users/${uid}/tarjeton`),
    driverOcassionalTripDocument: uid =>
      stRef(storage, `users/${uid}/ocassionalTripDocument`),
    singleBookingRef: bookingKey => ref(db, 'bookings/' + bookingKey),
    requestedDriversRef: bookingKey =>
      ref(db, 'bookings/' + bookingKey + '/requestedDrivers'),
    referralIdRef: referralId =>
      query(ref(db, 'users'), orderByChild('referralId'), equalTo(referralId)),
    trackingRef: bookingId => ref(db, 'tracking/' + bookingId),
    tasksRef: () =>
      query(ref(db, 'bookings'), orderByChild('status'), equalTo('NEW')),
    singleTaskRef: (uid, bookingId) =>
      ref(db, 'bookings/' + bookingId + '/requestedDrivers/' + uid),
    bookingListRef: (uid, role) =>
      role == 'customer'
        ? query(ref(db, 'bookings'), orderByChild('customer'), equalTo(uid))
        : role == 'driver'
        ? query(ref(db, 'bookings'), orderByChild('driver'), equalTo(uid))
        : role == 'fleetadmin'
        ? query(ref(db, 'bookings'), orderByChild('fleetadmin'), equalTo(uid))
        : role == 'cabCompany'
        ? query(ref(db, 'bookings'), orderByChild('cabCompany'), equalTo(uid))
        : ref(db, 'bookings'),
    chatRef: bookingId => ref(db, 'chats/' + bookingId + '/messages'),
    withdrawRef: ref(db, 'withdraws/'),
    languagesRef: ref(db, 'languages'),
    languagesEditRef: id => ref(db, 'languages/' + id),
    walletHistoryRef: uid => ref(db, 'walletHistory/' + uid),
    userNotificationsRef: uid => ref(db, 'userNotifications/' + uid),
    userRatingsRef: uid => ref(db, 'userRatings/' + uid),
    carsRef: (uid, role) =>
      role == 'driver'
        ? query(ref(db, 'cars'), orderByChild('driver'), equalTo(uid))
        : role == 'fleetadmin'
        ? query(ref(db, 'cars'), orderByChild('fleetadmin'), equalTo(uid))
        : ref(db, 'cars'),
    carAddRef: ref(db, 'cars'),
    carEditRef: id => ref(db, 'cars/' + id),
    carImage: id => stRef(storage, `cars/${id}`),
    allLocationsRef: ref(db, 'locations'),
    userLocationRef: uid => ref(db, 'locations/' + uid),
    passengerRatingRef: uid => ref(db, 'passengerRating/' + uid),
    carRef: id => query(ref(db, 'cars'), orderByChild('driver'), equalTo(id)),
  };
};

const FirebaseProvider = ({ config, children, AsyncStorage }) => {
  let app, auth, database, storage;

  if (!getApps().length) {
    try {
      app = initializeApp(config);
      if (typeof document !== 'undefined') {
        auth = initializeAuth(app, {
          persistence: browserLocalPersistence,
          popupRedirectResolver: browserPopupRedirectResolver,
        });
      } else {
        auth = initializeAuth(app, {
          persistence: getReactNativePersistence(AsyncStorage),
        });
      }
      database = getDatabase(app);
      storage = getStorage(app);
    } catch (error) {
      throw new Error('Error initializing app: ' + error);
    }
  } else {
    app = getApp();
    auth = getAuth(app);
    database = getDatabase(app);
    storage = getStorage(app);
  }

  firebase = createFullStructure(app, database, auth, storage, config);

  return (
    <FirebaseContext.Provider value={firebase}>
      {children}
    </FirebaseContext.Provider>
  );
};

export { firebase, FirebaseProvider, FirebaseContext };
